<script setup lang="ts">
const { $api } = useNuxtApp();

const props = defineProps<{
  auth: any | boolean;
}>();

// SSR에서 메뉴 데이터를 비동기로 가져오기
const menus = ref<any[]>([]);

onMounted(async () => {
  // await fetchAuth();
  menus.value = await $api('/v1/common/menus');
});
</script>

<template>
  <nav id="sidebar" class="sidebar">
    <a class="sidebar-brand" href="/main/dashboard">
      <svg>
        <use xlink:href="#ion-ios-pulse-strong" />
      </svg>
      BEEKK
    </a>
    <div class="sidebar-content">
      <div class="sidebar-user" v-if="auth">
        <img
          :src="auth.profile_image"
          class="img-fluid rounded-circle mb-2"
          alt="Linda Miller"
          onerror="this.src='/img/common__profilephoto-default.png'"
        />
        <div class="fw-bold">{{ auth.user_id }}</div>
        <small>{{ auth.user_nick }}</small>
      </div>

      <ul class="sidebar-nav">
        <li class="sidebar-header">Main</li>

        <li v-for="menu in menus" :key="menu.id" class="sidebar-item">
          <a
            :data-bs-target="menu.children.length > 0 ? '#' + menu.id : ''"
            :data-bs-toggle="menu.children.length > 0 ? 'collapse' : ''"
            :class="
              menu.children.length > 0
                ? 'sidebar-link collapsed'
                : 'sidebar-link'
            "
          >
            <i :class="`align-middle me-2 fas fa-fw ${menu.icon}`" />
            <span class="align-middle">{{ menu.name }}</span>
          </a>
          <ul
            :id="menu.id"
            class="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar"
          >
            <li
              v-for="submenu in menu.children"
              :key="submenu.id"
              class="sidebar-item"
            >
              <a class="sidebar-link" :href="submenu.path">{{
                submenu.name
              }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <svg width="0" height="0" style="position: absolute">
    <defs>
      <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
        <path
          d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z"
        ></path>
      </symbol>
    </defs>
  </svg>
</template>
