<template>
  <div class="wrapper">
    <AppSidebar :auth="auth" />
    <div class="main">
      <AppHeader />
      <slot />
      <AppFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import { Auth } from '@/types/auth/auth';

const { $api } = useNuxtApp();
const auth = ref<Auth | null>(null);
const router = useRouter();
const route = useRoute();

const fetchAuth = async () => {
  try {
    auth.value = await $api('/v1/auth/auth', {
      method: 'GET',
    });

    if (!auth.value) {
      window.location.href = `/login?redirect=${route.fullPath}`;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    await router.push('/');
  }
};

onMounted(async () => {
  await fetchAuth();
});
</script>
<style lang="scss">
main.content {
  margin-top: 1rem;
}
</style>
