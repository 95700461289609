<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row text-muted" v-if="false">
        <div class="col-8 text-start">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a class="text-muted" href="#">Support</a>
            </li>
            <li class="list-inline-item">
              <a class="text-muted" href="#">Privacy</a>
            </li>
            <li class="list-inline-item">
              <a class="text-muted" href="#">Terms of Service</a>
            </li>
            <li class="list-inline-item">
              <a class="text-muted" href="#">Contact</a>
            </li>
          </ul>
        </div>
        <div class="col-4 text-end">
          <p class="mb-0">
            &copy; 2023 -
            <a href="" class="text-muted">Spark</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>
